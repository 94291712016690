import { safeGet } from "@app/shared/utils/secure/safe-get";
import { createSelector } from "@ngrx/store";
import { selectProductState } from "../reducers";
import * as fromProduct from "../reducers/product.reducer";
import * as fromDiarySettingsSelectors from "./diary-settings.selectors";
// import * as RationSelectors from '../selectors/ration.selectors';

export const selectProductsIsLoading = () =>
  createSelector(selectProductState(), fromProduct.selectIsLoading);

export const selectProductsIsLoaded = () =>
  createSelector(selectProductState(), fromProduct.selectIsLoaded);

export const selectProductsIsFirstLoad = () =>
  createSelector(selectProductState(), fromProduct.selectIsFirstLoad);

export const selectProductIds = () =>
  createSelector(selectProductState(), fromProduct.selectProductIds);

export const selectProductEntities = () =>
  createSelector(selectProductState(), fromProduct.selectProductEntities);

export const selectAllProducts = () =>
  createSelector(selectProductState(), fromProduct.selectAllProducts);

export const selectProductTotal = () =>
  createSelector(selectProductState(), fromProduct.selectProductTotal);

export const selectProductFilter = () =>
  createSelector(selectProductState(), fromProduct.selectProductFilter);

export const selectAllProductsAddedToEating = () =>
  createSelector(
    selectAllProducts(),
    fromDiarySettingsSelectors.selectCurrentEating(),
    (allProducts, currentEating): any => {
      if (allProducts.length && currentEating) {
        return allProducts.map((product) => ({
          ...product,
          isAddedToEating: currentEating.food.some(
            (item: any) => item.product_id === product.id,
          ),
          product_id: product.id,
          id:
            currentEating.food.find(
              (item: any) => item.product_id === product.id,
            )?.id || null,
        }));
      }
    },
  );

export const selectCurrentRecommendedProductId = () =>
  createSelector(
    selectProductState(),
    fromProduct.getSelectedRecommendedProductId,
  );

export const selectCurrentRecommendedProduct = () =>
  createSelector(
    selectCurrentRecommendedProductId(),
    selectProductEntities(),
    (currentRecommendedProductId, productEntities) => {
      return safeGet(productEntities, String(currentRecommendedProductId));
    },
  );

export const selectAllProductsRecommendation = () =>
  createSelector(
    selectAllProducts(),
    selectCurrentRecommendedProductId(),
    (allProducts, сurrentRecommendedProductId) => {
      return allProducts.map((product) => ({
        ...product,
        isActive: product.id === сurrentRecommendedProductId,
        isRecommended: true,
      }));
    },
  );
