import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { translate } from "@jsverse/transloco";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { MonitoringService } from "@app/core/services/monitoring.service";
import { PatientsService } from "@app/core/services/patients.service";
import { ProfileService } from "@app/core/services/profile.service";
import { ToastService } from "@app/core/services/toast.service";
import { GreetingOnly } from "@app/profile/pages/questions-greeting/questions-greeting.component";
import { QuestionnaireService } from "@app/profile/services";
import { DiaryDetailsService } from "@app/profile/services/diary-details.service";
import * as fromProfile from "@app/profile/store";
import * as DiarySettingsActions from "@app/profile/store/actions/diary-settings.actions";
import * as PatientActions from "@app/profile/store/actions/patient.actions";
import * as PatientSelectors from "@app/profile/store/selectors/patient.selectors";
import * as QuestionsSettingsSelectors from "@app/profile/store/selectors/questions-settings.selectors";
import {
  RppAnswerData,
  RppDataModel,
} from "@app/shared/models/patient/rpp-data.model";
import { RppQuestionAnswer } from "@app/shared/models/patient/rpp-question-answer.mode";
import { RppQuestionType } from "@app/shared/models/patient/rpp-question-type.model";

@Injectable()
export class PatientEffects {
  checkPatientHash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientHash),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectPatientHash())),
      ),
      switchMap(([_, hash]) => {
        return this.patientsService.checkPatientHash(hash).pipe(
          switchMap((response) => {
            this.toastService.success(translate("toast.hello"));

            return [
              PatientActions.checkPatientHashSuccess({
                patientId: response.user_id,
              }),
              PatientActions.addProfileInfoToStorage({
                profileInfo: {
                  hash,
                  id: response.user_id,
                },
              }),
            ];
          }),
          catchError((error) => {
            return of(PatientActions.checkPatientHashFailure({ error }));
          }),
        );
      }),
    ),
  );

  // checkPatientSecretWord$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PatientActions.checkPatientSecretWord),
  //     withLatestFrom(
  //       this.store.pipe(select(PatientSelectors.selectPatientId())),
  //       this.store.pipe(select(PatientSelectors.selectProfileInfo())),
  //     ),
  //     switchMap(([{ secretWord }, patientId, profileInfo]) => {

  //       return this.patientsService
  //         .checkPatientSecretWord(secretWord, patientId || profileInfo.id)
  //         .pipe(
  //           switchMap((response) => {
  //             return [
  //               PatientActions.checkPatientSecretWordSuccess({
  //                 patient: response,
  //               }),
  //               PatientActions.addProfileInfoToStorage({
  //                 profileInfo: {
  //                   ...profileInfo,
  //                 },
  //               }),
  //             ];
  //           }),
  //           tap(() => this.router.navigate(["/profile/questions/main"])),
  //           catchError((error) => {
  //             this.toastService.error(
  //               translate("toast.secret-word-wrong"),
  //               {},
  //               7000,
  //               500,
  //             );

  //             return of(
  //               PatientActions.checkPatientSecretWordFailure({ error }),
  //             );
  //           }),
  //         );
  //     }),
  //   ),
  // );

  checkPatientSecretWordAndLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientSecretWordAndLogin),
      withLatestFrom(
        this.store.pipe(
          select(QuestionsSettingsSelectors.selectOnlyParameter()),
        ),
      ),
      switchMap(([{ secretWord, hash }, only]) => {
        return this.questionnaireService
          .loginPatientWithSecretWordAndHash(secretWord, hash)
          .pipe(
            switchMap(
              (response: {
                access_token: string;
                ration_id: number;
                week_id: number;
              }) => {
                const { access_token, ration_id, week_id } = response;
                this.diaryDetailsService.setHashId(hash);
                this.diaryDetailsService.setOnlyKey(only);

                return [
                  PatientActions.checkPatientSecretWordAndLoginSuccess({
                    access_token,
                  }),
                  DiarySettingsActions.updateActiveWeakId({ weakId: week_id }),
                  DiarySettingsActions.updateActiveRationId({
                    ration_id: ration_id,
                  }),
                ];
              },
            ),
            catchError((error) => {
              this.toastService.error(
                translate("toast.secret-word-wrong"),
                {},
                7000,
                500,
              );

              return of(
                PatientActions.checkPatientSecretWordAndLoginFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  checkPatientSecretWordAndLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.checkPatientSecretWordAndLoginSuccess),
      switchMap(() => [PatientActions.loadQuestionnairePatient()]),
    ),
  );

  loadQuestionnairePatient$ = createEffect(() => {
    let onlyParameter: GreetingOnly = null;

    return this.actions$.pipe(
      ofType(PatientActions.loadQuestionnairePatient),
      withLatestFrom(
        this.store.pipe(
          select(QuestionsSettingsSelectors.selectOnlyParameter()),
        ),
      ),
      switchMap(([_, only]) => {
        onlyParameter = only;

        return this.questionnaireService.loadPatient().pipe(
          switchMap((response: { data: any }) => {
            const { data } = response;

            return [
              PatientActions.loadQuestionnairePatientSuccess({
                patient: data,
              }),
            ];
          }),
          tap(() => {
            switch (onlyParameter) {
              case "rpp":
                this.router.navigate(["/profile/eating-disorder-survey"]);
                break;
              default:
                this.router.navigate(["/profile/questions/main"]);
                break;
            }
          }),
          catchError((error) => {
            this.toastService.error(
              translate("toast.patient-auth-errors"),
              {},
              7000,
              500,
            );

            return of(
              PatientActions.loadQuestionnairePatientFailure({ error }),
            );
          }),
        );
      }),
    );
  });

  loadCurrentPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.loadCurrentPatient),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectProfileInfo())),
      ),
      switchMap(([_, profileInfo]) => {
        return this.patientsService
          .checkPatientSecretWord(profileInfo.secretWord, profileInfo.id)
          .pipe(
            map((response) => {
              // this.toastService.success('Данные обновлены');
              return PatientActions.loadCurrentPatientSuccess({
                patient: response,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                translate("toast.data-not-updated"),
                {},
                7000,
                500,
              );

              return of(PatientActions.loadCurrentPatientFailure({ error }));
            }),
          );
      }),
    ),
  );

  getProfileInfoFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.getProfileInfoFromStorage),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectPatientHash())),
      ),
      switchMap(([_, hash]) => {
        return this.profileService.getProfileInfoFromStorage().pipe(
          switchMap((profileInfo) => {
            return [
              PatientActions.getProfileInfoFromStorageSuccess({ profileInfo }),
              ...(hash ? [PatientActions.checkPatientHash()] : []),
              ...(profileInfo?.secretWord
                ? [PatientActions.loadCurrentPatient()]
                : []),
            ];
          }),
          catchError((error) =>
            of(PatientActions.getProfileInfoFromStorageFailure({ error })),
          ),
        );
      }),
    ),
  );

  addProfileInfoToStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.addProfileInfoToStorage),
      mergeMap(({ profileInfo }) =>
        this.profileService.addProfileInfoToStorage(profileInfo).pipe(
          map(() =>
            PatientActions.addProfileInfoToStorageSuccess({ profileInfo }),
          ),
          catchError((error) =>
            of(PatientActions.addProfileInfoToStorageFailure({ error })),
          ),
        ),
      ),
    ),
  );

  updateCurrentPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.updateCurrentPatient),
      switchMap(({ patient }) => {
        return this.patientsService.updatePatient(patient).pipe(
          map((response) => {
            // this.toastService.success('Данные обновлены');
            return PatientActions.updateCurrentPatientSuccess({
              patient: response.data.patient,
            });
          }),
          catchError((error) => {
            this.toastService.error(
              translate("toast.data-not-updated"),
              {},
              7000,
              500,
            );

            return of(PatientActions.updateCurrentPatientFailure({ error }));
          }),
        );
      }),
    ),
  );

  updatePatientQuestionnaire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.updatePatientQuestionnaire),
      withLatestFrom(
        this.store.pipe(select(fromProfile.selectPatientState())),
        this.store.pipe(select(fromProfile.selectCurrentQuestionId())),
      ),
      switchMap(([{ patient }, patientInfo, questionId]) => {
        return this.questionnaireService.updateQuestionnaire(patient).pipe(
          map((response) => {
            this.toastService.success("Данные обновлены");

            const reopen = questionId <= patientInfo.completedStepsCount;

            if (patientInfo.completedStepsCount !== 12) {
              this.monitoringService.sendEventToAplitude(
                "event_questianery_step",
                {
                  step: questionId,
                  reopen,
                },
              );
            }

            return PatientActions.updatePatientQuestionnaireSuccess({
              patient: response.data.patient,
            });
          }),
          catchError((error) => {
            this.toastService.error(
              translate("toast.data-not-updated"),
              {},
              7000,
              500,
            );

            return of(
              PatientActions.updatePatientQuestionnaireFailure({ error }),
            );
          }),
        );
      }),
    ),
  );

  rppInitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.rppInitRequest),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectCurrentPatient())),
      ),
      switchMap(([_, patient]) => {
        return this.patientsService
          .updatePatient({
            ...patient,
            attributes: {
              ...patient?.attributes,
              data: {
                ...patient?.attributes?.data,
                rpp: new RppDataModel(),
              },
            },
          })
          .pipe(
            map((response) => {
              return PatientActions.rppInitRequestSuccess({
                patient: response.data.patient,
              });
            }),
          );
      }),
      catchError((error) => {
        return of(PatientActions.rppInitRequestFailure({ error }));
      }),
    ),
  );

  rppSendAnswersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientActions.rppSendAnswersRequest),
      withLatestFrom(
        this.store.pipe(select(PatientSelectors.selectCurrentPatient())),
      ),
      switchMap(([{ question, answer }, patient]) => {
        const rpp = patient?.attributes?.data?.rpp;
        const newAnswersData: Record<RppQuestionType, RppAnswerData> = {
          ...new RppDataModel().answersData,
          ...rpp?.answersData,
          [question.translation]: {
            ...new RppDataModel().answersData[question.translation],
            ...rpp?.answersData[question.translation],
            answer,
          },
        };

        return this.questionnaireService
          .updateQuestionnaire({
            ...patient,
            attributes: {
              ...patient?.attributes,
              data: {
                ...patient?.attributes?.data,
                rpp: {
                  ...rpp,
                  totalAnswered: Object.values(newAnswersData ?? {}).reduce(
                    (acc, curr) => {
                      return (
                        acc +
                        (curr?.answer !== RppQuestionAnswer.NO_ANSWER ? 1 : 0)
                      );
                    },
                    0,
                  ),
                  answersData: newAnswersData,
                },
              },
            },
          })
          .pipe(
            map((response) =>
              PatientActions.rppSendAnswersRequestSuccess({
                patient: response.data.patient,
              }),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromProfile.State>,
    private patientsService: PatientsService,
    private questionnaireService: QuestionnaireService,
    private diaryDetailsService: DiaryDetailsService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private router: Router,
    private monitoringService: MonitoringService,
  ) {}
}
