import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NUTRIENT_COMPOSITION } from "@app/core/consts/nutrient-composition";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class DiaryDietarySupplementService {
  constructor(private http: HttpClient) {}

  loadDietarySupplement(
    rationId: number,
    recipeFilter: any,
    general = NUTRIENT_COMPOSITION.ALL,
  ): Observable<any> {
    const { page, ...filter } = recipeFilter;

    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/dietary_supplement`,
      {
        params: {
          page,
          filter: JSON.stringify(filter),
          general,
        },
      },
    );
  }
}
