import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  ToastService,
  ToasterPosition,
} from "@app/core/services/toast.service";
import { DiaryService } from "@app/profile/services";
import * as fromProfile from "@app/profile/store";
import * as DiarySettingsActions from "@app/profile/store/actions/diary-settings.actions";
import * as DiarySettingsSelectors from "@app/profile/store/selectors/diary-settings.selectors";
import { isMobileDevice } from "@app/shared/utils/is-mobile-device";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { IndividualConfig } from "ngx-toastr";
import { catchError, map, of, switchMap, tap } from "rxjs";

@Injectable()
export class DiarySettingsEffects {
  loadRationDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.loadRationDays),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
      ]),
      switchMap(([_, rationId, weekId]) => {
        return this.diaryService.loadRationDays(rationId, weekId).pipe(
          map((response: any) => {
            // this.toastService.success(response.message);

            if (response.data.week.length) {
              const [firstDay] = response.data.week;
              this.store.dispatch(
                DiarySettingsActions.navigateToRationDay({
                  dayId: firstDay.id,
                }),
              );
            }

            return DiarySettingsActions.loadRationDaysSuccess({
              norms: response.data.norms,
              week: response.data.week,
              eatings: response.data.eatings,
            });
          }),

          catchError((error) => {
            return of(DiarySettingsActions.loadRationDaysFailure({ error }));
          }),
        );
      }),
    ),
  );

  navigateToRationDay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiarySettingsActions.navigateToRationDay),
        tap(({ dayId }) => {
          this.router.navigate([`/profile/diary/${dayId}`]);
        }),
      ),
    { dispatch: false },
  );

  addDayRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.addDayRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
      ]),
      switchMap(([_, rationId, weekId]) => {
        return this.diaryService.addDayRation(rationId, weekId).pipe(
          map((response) => {
            const { week } = response.data;
            this.toastService.success(response.message);

            // if (response.data.redirectWeekId) {
            //   return DiarySettingsActions.navigateToRationDays({
            //     weekId: response.data.redirectWeekId,
            //   });
            // }

            // !Придумать чтото другое, обсудить с Алексеем
            // **если в массиве есть первый день, переключаемся на него
            // if (response.data.week.length === 1) {
            //   const [firstDay] = response.data.week;
            //   return DiarySettingsActions.navigateToRationDay({
            //     dayId: firstDay.id,
            //   });
            // }

            try {
              if (week?.length && week instanceof Array) {
                const lastDay = week.at(-1);
                const { id } = lastDay;
                this.router.navigateByUrl("/profile/diary/" + id);
              }
            } catch (err) {
              console.debug(err);
            }

            return DiarySettingsActions.addDayRationSuccess({
              week: response.data.week,
            });
          }),
          catchError((error) => {
            return of(DiarySettingsActions.addDayRationFailure({ error }));
          }),
        );
      }),
    ),
  );

  removeDayRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.removeDayRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([{ day }, rationId, weekId, selectedDayId]) => {
        return this.diaryService
          .removeDayRation(rationId, weekId, selectedDayId, day.id)
          .pipe(
            map((response) => {
              this.toastService.success(response.message);

              if (response.data.redirectDayId) {
                return DiarySettingsActions.navigateToRationDay({
                  dayId: response.data.redirectDayId,
                });
              }

              // if (response.data.redirectWeekId) {
              //   return DiarySettingsActions.navigateToRationDays({
              //     weekId: response.data.redirectWeekId,
              //   });
              // }

              return DiarySettingsActions.removeDayRationSuccess({
                week: response.data.week,
              });
            }),
            catchError((error) => {
              return of(DiarySettingsActions.removeDayRationFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadRationDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.loadRationDay),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([_, rationId, weekId, dayId]) => {
        if (!dayId) return [];

        return this.diaryService.loadRationDay(rationId, weekId, dayId).pipe(
          map((response) => {
            return DiarySettingsActions.loadRationDaySuccess({
              norms: response.data.norms,
              week: response.data.week,
              eatings: response.data.eatings,
              description: response.data.description,
            });
          }),
          catchError((error) => {
            return of(DiarySettingsActions.loadRationDayFailure({ error }));
          }),
        );
      }),
    ),
  );

  updateEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.updateEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([{ selectedEatingCard }, rationId, weekId, dayId]) => {
        return this.diaryService
          .updateEatingRation(selectedEatingCard, rationId, weekId, dayId)
          .pipe(
            map((response) => {
              this.toastService.success(response.message);

              return DiarySettingsActions.updateEatingRationSuccess({
                eating: response.data,
              });
            }),
            catchError((error) => {
              return of(
                DiarySettingsActions.updateEatingRationFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  removeEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.removeEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([{ selectedEatingCard }, rationId, weekId, dayId]) => {
        return this.diaryService
          .removeEatingRation(selectedEatingCard, rationId, weekId, dayId)
          .pipe(
            map((response) => {
              this.toastService.success(response.message);

              return DiarySettingsActions.removeEatingRationSuccess({
                norms: response.data,
                selectedEatingCard,
              });
            }),
            catchError((error) => {
              return of(
                DiarySettingsActions.removeEatingRationFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  updateFoodInEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.updateFoodInEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([{ selectedFoodCard }, rationId, weekId, dayId]) => {
        return this.diaryService
          .updateFoodRation(selectedFoodCard, rationId, weekId, dayId)
          .pipe(
            map((response) => {
              this.toastService.success(response.message);

              return DiarySettingsActions.updateFoodInEatingRationSuccess({
                norms: response.data.norms,
                eating: response.data.eating,
              });
            }),
            catchError((error) => {
              return of(
                DiarySettingsActions.updateFoodInEatingRationFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  addEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.addEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([_, rationId, weekId, dayId]) => {
        return this.diaryService.addEatingRation(rationId, weekId, dayId).pipe(
          map((response) => {
            this.toastService.success(response.message);

            return DiarySettingsActions.addEatingRationSuccess({
              eating: response.data,
            });
          }),
          catchError(({ error }) => {
            return of(DiarySettingsActions.addEatingRationFailure({ error }));
          }),
        );
      }),
    ),
  );

  toggleFoodInEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.toggleFoodInEatingRation),
      map(({ selectedFoodCard }) => {
        return selectedFoodCard.isAddedToEating
          ? DiarySettingsActions.removeFoodInEatingRation({ selectedFoodCard })
          : DiarySettingsActions.addFoodToEatingRation({ selectedFoodCard });
      }),
    ),
  );

  addFoodToEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.addFoodToEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveEatingId)),
      ]),
      switchMap(([{ selectedFoodCard }, rationId, weekId, dayId, eatingId]) => {
        const options: Partial<IndividualConfig<any>> = {};
        if (isMobileDevice()) {
          options.positionClass = ToasterPosition.bottomLeft;
        }

        return this.diaryService
          .addFoodToEatingRation(
            selectedFoodCard,
            rationId,
            weekId,
            dayId,
            eatingId,
          )
          .pipe(
            map((response) => {
              this.toastService.success(response.message, options);

              return DiarySettingsActions.addFoodToEatingRationSuccess({
                norms: response.data.norms,
                eating: response.data.eating,
              });
            }),
            catchError(({ error }) => {
              this.toastService.error(error, options, 7000, 500);

              return of(
                DiarySettingsActions.addFoodToEatingRationFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  removeFoodInEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.removeFoodInEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
      ]),
      switchMap(([{ selectedFoodCard }, rationId, weekId, dayId]) => {
        const options: Partial<IndividualConfig<any>> = {};
        if (isMobileDevice()) {
          options.positionClass = ToasterPosition.bottomLeft;
        }

        return this.diaryService
          .removeFoodRation(selectedFoodCard, rationId, weekId, dayId)
          .pipe(
            map((response) => {
              this.toastService.success(response.message, options);

              return DiarySettingsActions.removeFoodInEatingRationSuccess({
                norms: response.data.norms,
                eating: response.data.eating,
              });
            }),
            catchError((error) => {
              this.toastService.error(error, options, 7000, 500);

              return of(
                DiarySettingsActions.removeFoodInEatingRationFailure({ error }),
              );
            }),
          );
      }),
    ),
  );

  loadWeeksForCopyEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.loadWeeksForCopyEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
      ]),
      switchMap(([_, rationId]) => {
        return this.diaryService.loadWeeksForCopyEatingRation(rationId).pipe(
          map((response) => {
            return DiarySettingsActions.loadWeeksForCopyEatingRationSuccess({
              existingWeeksAndDays: response.data.weeks,
            });
          }),
          catchError((error) => {
            return of(
              DiarySettingsActions.loadWeeksForCopyEatingRationFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  replaceFoodInEatingRation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiarySettingsActions.replaceFoodInEatingRation),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingsSelectors.selectRationId())),
        this.store.pipe(select(DiarySettingsSelectors.selectedWeakId())),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveDayId)),
        this.store.pipe(select(DiarySettingsSelectors.selectActiveEatingId)),
      ]),
      switchMap(
        ([{ selectedFoodCard }, rationId, weekId, dayId, eatingFoodId]) => {
          return this.diaryService
            .replaceFoodRation(
              selectedFoodCard,
              rationId,
              weekId,
              dayId,
              eatingFoodId,
            )
            .pipe(
              map((response) => {
                this.toastService.success(response.message);

                return DiarySettingsActions.replaceFoodInEatingRationSuccess({
                  norms: response.data.norms,
                  eating: response.data.eating,
                });
              }),
              catchError((error) => {
                return of(
                  DiarySettingsActions.replaceFoodInEatingRationFailure({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromProfile.State>,
    private diaryService: DiaryService,
    private toastService: ToastService,
    private router: Router,
  ) {}
}
